import React from 'react';
import { graphql } from 'gatsby';

import { withPreview } from 'gatsby-source-prismic';

// Import Components
import WayFadeUp from '../components/transitions/WayFadeUp';
import Layout from '../components/layout';
import SEO from '../components/seo';
import VideoBanner from '../components/VideoBanner';
import BeltDragger from '../components/BeltDragger';

// Import Query
import { HomePageQuery } from '../GraphQl/queries'; // eslint-disable-line

const IndexPage = ({ data: { prismicHomePage } }) => {
  const { data } = prismicHomePage;
  const { quote, text, text_two } = data;
  return (
    <Layout headerColor="white">
      <SEO title="Home" />
      <VideoBanner video={data.video} headline={data.headline} />
      <WayFadeUp>
        <div id="intro" className="mt4 mb4 mt6-l mb6-l pl4 pr4 tl tc-l">
          <div className="f2 f1-l grey measure-narrow center lh-copy tiempos">{quote.text}</div>

          <div className="center lh-copy f4 tl grey mt5-l mw8 flex-l flex-wrap">
            <div className="w-50-l pr4-l" dangerouslySetInnerHTML={{ __html: text.html }} />
            <div className="w-50-l pl4-l" dangerouslySetInnerHTML={{ __html: text_two.html }} />
          </div>
        </div>
      </WayFadeUp>
      <BeltDragger images={data.image_carousel} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicHomePage {
      ...HomeBody
    }
  }
`;

export default withPreview(IndexPage);
